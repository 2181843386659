<template>
  <div class="container">
    <Overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <Loading />
      </div>
    </Overlay>
    <div class="status">换绑失败</div>
    <div class="con-auth flex-center">
      <div class="flex-column">
        <img class="con-auth_icon" :src="authInfoOld.appImg" alt="">
        <span class="con-auth_title">{{ authInfoOld.appName }}</span>
      </div>
      <img class="con-auth_center" :src="require('../assets/trill_lock_icon.png')" alt="">
      <div class="flex-column">
        <img class="con-auth_icon" :src="authInfo.appImg" alt="">
        <span class="con-auth_title">{{ authInfo.appName }}</span>
      </div>
    </div>
    <div class="tip">
      <span>您的抖音号已被锁定，请联系“{{ authInfoOld.appName }}”小程序客服进行解锁后重试，ID：{{ bindId }}</span>
    </div>
  </div>
</template>
<script>

import { getTrillLockInfo } from '../api/trillAuth'
import { Overlay, Loading } from 'vant';
export default {
  name: "TrillLock",
  components: {
    Overlay,
    Loading
  },
  data() {
    return {
      bindId: '',
      userId: '',
      authInfoOld: {
        appImg: '',
        appName: '',
      },
      authInfo: {
        appImg: '',
        appName: ''
      },
      show: false
    }
  },
  created() {
    document.title = '结果页'
    if (this.$route.query.bindId) {
      this.userId = this.$route.query.state
      this.bindId = this.$route.query.bindId
    }
    this.getInfo()
  },
  methods: {
    async getInfo() {
      this.show = true
      const { data } = await getTrillLockInfo({ state: this.userId, bindId: this.bindId })
      this.authInfo = Object.assign({}, data.authInfo)
      this.authInfoOld = Object.assign({}, data.authInfoOld)
      this.show = false
    }
  }
}
</script>

<style scoped>
.container {
  background: #fff;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 17.07vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.status {
  font-size: 20px;
  color: #666;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.con-auth {
  margin-top: 10.4vw;
  margin-bottom: 10.9vw;
}

.con-auth_icon {
  width: 14.93vw;
  height: 14.93vw;
}

.con-auth_center {
  width: 6.93vw;
  height: 7.73vw;
  margin: 5.6vw 6.67vw 0 9.33vw;
  align-self: flex-start;
}

.con-auth_title {
  color: #232323;
  font-size: 12px;
  margin-top: 2.4vw;
}

.tip {
  width: 64vw;
  margin: 0 auto;
  margin-top: 6.4vw;
  font-size: 12px;
  line-height: 18px;
  color: #666;
  text-align: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
